import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './TargetGroupView.module.css';
import { AddIcon, DuplicateIcon, PeopleIcon, toaster } from 'evergreen-ui';
import AppButton from 'components/AppButton/AppButton';
import ProgressSpinner from 'components/ProgressSpinner/ProgressSpinner';
import AddParticipantsTable from 'views/TargetGroupView/AddParticipantsTable/AddParticipantsTable';
import {
    actions as targetGroupsActions,
    actions as targetGroupActions,
} from 'store/targetGroups/targetGroups.actions';
import { actions as usersActions } from 'store/users/users.actions';
import { actions as assessmentActions } from 'store/assessments/assessments.actions';
import TargetGroupTable from 'views/TargetGroupView/TargetGroupTable/TargetGroupTable';
import { CreateUserSchema, Participant } from 'typings/user';
import TargetGroupModals from './TargetGroupModals/TargetGroupModals';
import { selectAssessmentsById } from 'store/assessments/assessments.selectors';
import {
    selectFetchingUsers,
    selectTargetGroupsById,
} from 'store/users/users.selectors';
import {
    selectReportsNotSent,
    selectResetScanLoading,
    selectTargetGroupCandidateScanReset,
    selectTargetGroupDataById,
    selectTargetGroupState,
    selectTargetGroupWorkPreferenceBaseline,
    selectUpdatingParticipants,
} from 'store/targetGroups/targetGroups.selectors';
import { TargetGroupSettings } from 'views/TargetGroupView/TargetGroupSettings/TargetGroupSettings';
import { selectCompaniesState } from 'store/companies/companies.selectors';
import { Company } from '../../store/companies/companies.reducer';
import { TargetGroupParticipant } from '../../typings/target_group';
import { AuthenticatedUserProps } from '../../hoc/WithAuthLayout';
import TargetGroupScan from './TargetGroupScan/TargetGroupScan';
import TargetGroupScoreDownload from './TargetGroupScoreDownload/TargetGroupScoreDownload';
import { getWorkPreferencesFormattedData } from './utils';
import WorkPreferenceBaseLineBarChart from '../../components/WorkPreferenceBaseLineBarChart';
import TargetGroupFeedbackDownload from './TargetGroupFeedbackDownload/TargetGroupFeedbackDownload';
import { APP_ENVIRONMENT } from '../../utils/environment-variables';
import { ReactComponent as WhiteCheckMarkIcon } from '../../assets/Icons/white-check-mark.svg';

interface TargetGroupViewProps extends AuthenticatedUserProps {}

const TargetGroupView: FunctionComponent<TargetGroupViewProps> = (props) => {
    const candidatesPerPage = 50;
    const { userRoles, hasMultipleCompanies } = props;
    const isSuperAdmin = userRoles.includes('super-admin');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { assessmentId, targetGroupId } = useParams();

    // Store Selectors
    const { companies } = useSelector(selectCompaniesState);
    const assessments = useSelector(selectAssessmentsById);
    const targetGroup = useSelector(selectTargetGroupDataById(targetGroupId));
    const candidateScanReset = useSelector(
        selectTargetGroupCandidateScanReset(targetGroupId),
    );
    const workPreferenceBaseline =
        useSelector(selectTargetGroupWorkPreferenceBaseline(targetGroupId)) ||
        [];
    const targetGroupsFromStore = useSelector(selectTargetGroupState);
    const participantsList = useSelector(selectTargetGroupsById(targetGroupId));
    const fetchingUsers = useSelector(selectFetchingUsers);
    const updatingParticipants = useSelector(
        selectUpdatingParticipants(targetGroupId),
    );
    const resetScanLoading = useSelector(selectResetScanLoading(targetGroupId));
    const reportsNotSent = useSelector(selectReportsNotSent);

    const companyId =
        assessmentId &&
        assessments &&
        assessments[assessmentId] &&
        assessments[assessmentId].data.company_id
            ? assessments[assessmentId].data.company_id
            : '';
    const currentCompany = companies.find(
        (company: Company) => company.id === companyId,
    );
    const scan =
        assessmentId && assessments[assessmentId]
            ? assessments[assessmentId].data
            : undefined;
    const assessmentName =
        assessmentId &&
        assessments[assessmentId] &&
        assessments[assessmentId].data.name;
    const assessmentLanguage =
        assessmentId &&
        assessments[assessmentId] &&
        assessments[assessmentId].data.language;

    const name = targetGroup ? targetGroup.name : '';
    const isTargetGroupPublic = targetGroup?.config?.is_public
        ? targetGroup.config.is_public
        : false;
    const participants = useMemo(
        () => (targetGroup ? targetGroup.participants : []),
        [targetGroup],
    );

    // State
    const [candidateCurrentPage, setCandidateCurrentPage] = useState<number>(0);
    const [shouldShowConfirmModal, showConfirmModal] = useState<boolean>(false);
    const [shouldShowInviteModal, showInviteModal] = useState<boolean>(false);
    const [shouldShowRemoveModal, showRemoveModal] = useState<boolean>(false);
    const [shouldShowFullDeleteCandidateModal, showFullDeleteCandidateModal] =
        useState<boolean>(false);
    const [shouldShowResetCandidateScanModal, showResetCandidateScanModal] =
        useState<boolean>(false);
    const [
        shouldShowResetCandidateScanSuccessModal,
        setResetCandidateScanSuccessModal,
    ] = useState<boolean>(false);
    const [shouldShowDeleteTargetGroupModal, showDeleteTargetGroupModal] =
        useState<boolean>(false);
    const [shouldShowReminderModal, showReminderModal] =
        useState<boolean>(false);
    const [shouldShowResetPasswordModal, showResetPasswordModal] =
        useState<boolean>(false);
    const [shouldShowReportModal, showReportModal] = useState<boolean>(false);
    const [addingParticipants, setAddingParticipants] =
        useState<boolean>(false);
    const [participantsSelected, setSelectedParticipants] = useState<
        Participant[]
    >([]);
    const [candidateTotalPages, setCandidateTotalPages] = useState<number[]>(
        [],
    );
    const [isLinkCopied, setIsLinkCopied] = useState(false);

    const baseUrl =
        APP_ENVIRONMENT === 'local'
            ? 'http://localhost:3000'
            : APP_ENVIRONMENT === 'dev'
            ? 'https://dev-demo.neurolytics.ai'
            : 'https://scan.neurolytics.ai';

    const registrationUrl = isTargetGroupPublic
        ? `${baseUrl}/register/${
              currentCompany?.slug ? currentCompany.slug + '/' : ''
          }${targetGroupId}`
        : '';

    let isUsersDetailsLoading = false;
    if (!!participantsList && !!Object.keys(participantsList).length) {
        isUsersDetailsLoading = Object.keys(participantsList).every(
            (participant) =>
                !participantsList[participant] ||
                participantsList[participant].loading_activity ||
                participantsList[participant].loading_emails_info,
        );
    }

    useEffect(() => {
        if (Object.keys(participants).length > candidatesPerPage) {
            const numberOfPages = Math.ceil(
                Object.keys(participants).length / candidatesPerPage,
            );
            setCandidateTotalPages(Array.from(Array(numberOfPages).keys()));
        }
    }, [participants]);

    useEffect(() => {
        if (
            participants &&
            Object.keys(participants).length < candidatesPerPage + 1 &&
            ((candidateCurrentPage === 0 && candidateTotalPages.length === 1) ||
                (candidateCurrentPage === 0 &&
                    candidateTotalPages.length === 2))
        ) {
            setCandidateTotalPages([]);
        }
    }, [candidateCurrentPage, candidateTotalPages.length, participants]);

    // fetch data on first load
    useEffect(() => {
        if (assessmentId && targetGroupId) {
            dispatch(assessmentActions.getAssessment(assessmentId));
            dispatch(
                targetGroupActions.getTargetGroupById(
                    assessmentId,
                    targetGroupId,
                ),
            );
            dispatch(
                targetGroupActions.getWorkPreferenceBaseline(
                    assessmentId,
                    targetGroupId,
                ),
            );
        }
    }, [assessmentId, dispatch, targetGroupId]);

    useEffect(() => {
        if (assessmentId && targetGroupId && !targetGroup) {
            dispatch(
                targetGroupsActions.getAssessmentsTargetGroups(assessmentId),
            );
        }
    }, [assessmentId, dispatch, targetGroupId, targetGroup]);

    useEffect(() => {
        if (assessmentId && targetGroupId && !updatingParticipants) {
            dispatch(
                usersActions.getTargetGroupUsers(
                    assessmentId,
                    targetGroupId,
                    candidateCurrentPage,
                    candidatesPerPage,
                ),
            );
        }
    }, [
        assessmentId,
        candidateCurrentPage,
        dispatch,
        targetGroupId,
        updatingParticipants,
        participants.length,
    ]);

    useEffect(() => {
        if (targetGroup) {
            setResetCandidateScanSuccessModal(candidateScanReset);
        }
    }, [candidateScanReset, targetGroup]);

    // notify about failed report emails
    // todo: where do we clear this reportsNotSent object on redux?!
    useEffect(() => {
        if (reportsNotSent && reportsNotSent.length > 0) {
            reportsNotSent.forEach((userId: string) => {
                const participant =
                    participants.length &&
                    participants.find(
                        (participant: TargetGroupParticipant) =>
                            participant.user_id === userId,
                    );
                if (participant) {
                    toaster.danger(
                        `The email was not sent to ${participant.user_email}, probably because there is no report to send.`,
                    );
                }
            });
        }
    }, [participants, reportsNotSent]);

    const data = getWorkPreferencesFormattedData(workPreferenceBaseline);

    if (!assessmentId || !targetGroupId) {
        return <></>;
    }

    const scanGroupsCount = targetGroupsFromStore.byAssessmentId[assessmentId]
        ?.list
        ? targetGroupsFromStore.byAssessmentId[assessmentId]?.list.length
        : 0;

    const onAddParticipants = (participants: CreateUserSchema[]) => {
        setAddingParticipants(false);
        showConfirmModal(false);
        dispatch(
            targetGroupActions.addParticipants(
                assessmentId,
                targetGroupId,
                participants
            ),
        );
        setCandidateCurrentPage(0);
    };

    const deleteEmptyTargetGroup = () => {
        dispatch(
            targetGroupActions.deleteEmptyTargetGroup(
                assessmentId,
                targetGroupId,
                navigate,
                currentCompany?.slug,
            ),
        );
    };

    const getParticipant = (userId: string): Participant | null => {
        return participantsList && participantsList[userId]
            ? participantsList[userId]
            : null;
    };

    const convertVideos = (userId: string) => {
        dispatch(usersActions.convertUserVideos(targetGroupId, userId));
        toaster.notify(
            'Videos are getting converted. This may take a 2-3 minutes',
        );
        setTimeout(() => {
            toaster.notify(
                'Reload the page in a few minutes to see if they have been successfully converted',
            );
        }, 1000);
    };

    const onUserSelectChange = (userId: string, event: any) => {
        if (event.target.checked === true) {
            const selected = [...participantsSelected];
            const participant = getParticipant(userId);
            if (participant) {
                selected.push(participant);
                setSelectedParticipants(selected);
            }
        } else if (event.target.checked === false) {
            const selected = [...participantsSelected].filter(
                (participant) => participant.id !== userId,
            );
            setSelectedParticipants(selected);
        }
    };

    const onUserSelectChangeAll = () => {
        if (
            Object.keys(participantsList).length !== participantsSelected.length
        ) {
            setSelectedParticipants(Object.values(participantsList));
        } else {
            setSelectedParticipants([]);
        }
    };

    const discardModals = () => {
        showInviteModal(false);
        showResetPasswordModal(false);
        showReminderModal(false);
        showReportModal(false);
        showRemoveModal(false);
        showFullDeleteCandidateModal(false);
        showResetCandidateScanModal(false);
        showDeleteTargetGroupModal(false);
        setResetCandidateScanSuccessModal(false);
    };

    const isReportReadyToBeSent = (participant: Participant): boolean => {
        return !!(
            participant.activity &&
            participant.activity.report &&
            participant.activity.report.id
        );
    };

    const sendResetPasswordEmails = () => {
        const userIds = [...participantsSelected].map(
            (participant) => participant.id,
        );
        // eslint-disable-next-line array-callback-return
        userIds.map((userId) => {
            dispatch(
                usersActions.sendEmail(
                    assessmentId,
                    targetGroupId,
                    userId,
                    'resetPassword',
                ),
            );
        });
        discardModals();
    };

    const sendReminderEmails = () => {
        const userIds = [...participantsSelected].map(
            (participant) => participant.id,
        );
        // eslint-disable-next-line array-callback-return
        userIds.map((userId) => {
            dispatch(
                usersActions.sendEmail(
                    assessmentId,
                    targetGroupId,
                    userId,
                    'reminder',
                ),
            );
        });
        discardModals();
    };

    const selectedParticipantsThatHaveReport = (): Participant[] => {
        return [...participantsSelected].filter((participant) =>
            isReportReadyToBeSent(participant),
        );
    };

    const sendReportEmails = () => {
        const participantsWithReport = selectedParticipantsThatHaveReport();
        const userIds = [...participantsWithReport].map(
            (participant) => participant.id,
        );
        // eslint-disable-next-line array-callback-return
        userIds.map((userId) => {
            dispatch(
                usersActions.sendEmail(
                    assessmentId,
                    targetGroupId,
                    userId,
                    'report',
                ),
            );
        });
        discardModals();
    };

    const selectedParticipantsThatNotFinishedScan = (): Participant[] => {
        return [...participantsSelected].filter(
            (participant) =>
                !participant.activity ||
                !participant.activity.sessions ||
                !participant.activity.sessions.length ||
                !participant.activity.sessions[0].finishedAt,
        );
    };

    const updatingTableAfterRemovingCandidates = () => {
        if (
            candidateCurrentPage === candidateTotalPages.length - 1 &&
            candidateCurrentPage !== 0 &&
            participantsSelected.length === Object.keys(participantsList).length
        ) {
            setCandidateTotalPages(
                Array.from(Array(candidateTotalPages.length - 1).keys()),
            );
            setCandidateCurrentPage(candidateCurrentPage - 1);
        }
    };

    const removeCandidates = () => {
        const formattedParticipants = [
            ...selectedParticipantsThatNotFinishedScan(),
        ].map((participant) => ({
            user_id: participant.id,
            user_email: participant.email,
        }));
        dispatch(
            targetGroupActions.removeParticipants(
                assessmentId,
                targetGroupId,
                formattedParticipants,
            ),
        );

        const newSelected = [...participantsSelected].filter((selected) => {
            return !participants.find(
                (participant: TargetGroupParticipant) =>
                    participant.user_id === selected.id,
            );
        });

        setSelectedParticipants(newSelected);
        discardModals();
        updatingTableAfterRemovingCandidates();
    };

    const fullDeleteCandidates = () => {
        const formattedParticipants = [...participantsSelected].map(
            (participant) => ({
                user_id: participant.id,
                user_email: participant.email,
            }),
        );
        dispatch(
            targetGroupActions.fullDeleteParticipants(
                assessmentId,
                targetGroupId,
                formattedParticipants,
            ),
        );

        const newSelected = [...participantsSelected].filter((selected) => {
            return !participants.find(
                (participant: TargetGroupParticipant) =>
                    participant.user_id === selected.id,
            );
        });

        setSelectedParticipants(newSelected);
        discardModals();
        updatingTableAfterRemovingCandidates();
    };

    const resetCandidateScan = () => {
        dispatch(
            targetGroupActions.resetCandidateScan(
                companyId,
                targetGroupId,
                participantsSelected[0].id,
            ),
        );
        discardModals();
    };

    const handleChangePage = (page: number) => {
        setCandidateCurrentPage(page);
        setSelectedParticipants([]);
    };

    async function copyTextToClipboard(text: string) {
        return await navigator.clipboard.writeText(text);
    }

    const handleCopyClick = () => {
        copyTextToClipboard(registrationUrl).then(() => {
            setIsLinkCopied(true);
            setTimeout(() => {
                setIsLinkCopied(false);
            }, 2000);
        });
    };

    return (
        <div className={styles.target_group_view}>
            <div className={styles.target_group_info_box}>
                <div className={styles.target_group_info_box__wrapper}>
                    <div className={styles.target_group_info_box__info}>
                        {name && (
                            <div className={styles.target_group_info_box__name}>
                                <h3
                                    className={
                                        styles.target_group_info_box__company_name
                                    }>
                                    {hasMultipleCompanies ? (
                                        <Link
                                            className={
                                                styles.target_group_info_box__company_name__color
                                            }
                                            to={`/${
                                                currentCompany
                                                    ? currentCompany.slug
                                                    : ''
                                            }`}>
                                            {currentCompany
                                                ? currentCompany.name
                                                : ''}
                                        </Link>
                                    ) : (
                                        <span
                                            className={
                                                styles.target_group_info_box__company_name__color
                                            }>
                                            {currentCompany
                                                ? currentCompany.name
                                                : ''}
                                        </span>
                                    )}
                                </h3>
                                <h2>{name}</h2>
                            </div>
                        )}
                        <div className={styles.number_of_candidates}>
                            <div className={styles.number_of_candidates__icon}>
                                <PeopleIcon size={13} />
                            </div>
                            <span className={styles.number_of_candidates__text}>
                                {participants.length === 1 &&
                                targetGroup?.type === 'CANDIDATE'
                                    ? `1 candidate`
                                    : participants.length === 1 &&
                                      targetGroup?.type === 'INTERNAL'
                                    ? `1 participant`
                                    : participants.length !== 1 &&
                                      targetGroup?.type === 'INTERNAL'
                                    ? `${participants.length} participants`
                                    : `${participants.length} candidates`}
                            </span>
                        </div>
                    </div>
                    <TargetGroupSettings
                        assessmentId={assessmentId}
                        targetGroupId={targetGroupId}
                        companySlug={currentCompany?.slug}
                        registrationUrl={registrationUrl}
                    />
                    <div
                        className={styles.target_group_info_box__public_link}
                        style={{
                            border: isTargetGroupPublic
                                ? '1px solid #c1c4d6'
                                : 'none',
                        }}>
                        {isTargetGroupPublic && (
                            <>
                                <AppButton
                                    icon={DuplicateIcon}
                                    color={'grayWhite'}
                                    fontSize="14px"
                                    fontWeight="normal"
                                    onClick={handleCopyClick}>
                                    Copy registration link
                                </AppButton>
                                {isLinkCopied && (
                                    <div
                                        className={
                                            styles.target_group_info_box__copy_tooltip
                                        }>
                                        <WhiteCheckMarkIcon />
                                        <p>URL copied</p>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className={styles.target_group_info_box__add_participant}>
                    <TargetGroupScoreDownload
                        assessmentName={assessmentName}
                        targetGroupName={name}
                        participants={participants}
                        participantsList={participantsList}
                        targetGroup={targetGroup}
                        candidateCurrentPage={candidateCurrentPage}
                        candidateTotalPages={candidateTotalPages}
                    />
                    {isSuperAdmin && currentCompany && (
                        <TargetGroupFeedbackDownload
                            companyId={companyId}
                            companyName={currentCompany.name}
                            targetGroupId={targetGroupId}
                            assessmentName={assessmentName}
                            targetGroupName={name}
                            assessmentLanguage={assessmentLanguage}
                        />
                    )}

                    <div>
                        {!addingParticipants && (
                            <AppButton
                                justifyContent={'flex-start'}
                                width={'auto'}
                                icon={AddIcon}
                                onClick={() => setAddingParticipants(true)}>
                                Add candidates
                            </AppButton>
                        )}
                    </div>
                </div>
            </div>
            {!addingParticipants && (
                <React.Fragment>
                    {fetchingUsers && <ProgressSpinner />}
                    {!fetchingUsers && participantsList && (
                        <TargetGroupTable
                            scan={scan}
                            candidateTotalPages={candidateTotalPages}
                            candidateCurrentPage={candidateCurrentPage}
                            candidatesPerPage={candidatesPerPage}
                            isUsersDetailsLoading={isUsersDetailsLoading}
                            handleChangePage={(page: number) =>
                                handleChangePage(page)
                            }
                            targetGroupId={targetGroupId}
                            participants={participantsList}
                            participantsSelected={participantsSelected}
                            areNoParticipants={participants.length === 0}
                            isUsersInfoLoading={!participantsList}
                            onSelect={(userId: string, event: any) =>
                                onUserSelectChange(userId, event)
                            }
                            onSelectAll={() => onUserSelectChangeAll()}
                            onConvertVideos={convertVideos}
                            // onSendInvitationEvent={() => showInviteModal(true)}
                            onSendResetPasswordEvent={() =>
                                showResetPasswordModal(true)
                            }
                            onSendReminderEvent={() => showReminderModal(true)}
                            onSendReportEvent={() => showReportModal(true)}
                            updatingParticipants={updatingParticipants}
                            resetScanLoading={resetScanLoading}
                            onRemoveCandidateEvent={() => showRemoveModal(true)}
                            onFullDeleteCandidateEvent={() =>
                                showFullDeleteCandidateModal(true)
                            }
                            onResetCandidateScanEvent={() =>
                                showResetCandidateScanModal(true)
                            }
                            isSuperAdmin={isSuperAdmin}
                        />
                    )}
                </React.Fragment>
            )}

            {addingParticipants && (
                <AddParticipantsTable
                    onSubmit={(event) => {
                        onAddParticipants(event);
                    }}
                    onCancel={() => setAddingParticipants(false)}
                    showConfirmModal={(arg: boolean) => showConfirmModal(arg)}
                    shouldShowConfirmModal={shouldShowConfirmModal}
                    onAddParticipants={(participants: CreateUserSchema[]) =>
                        onAddParticipants(participants)
                    }
                    assessmentId={assessmentId}
                />
            )}
            {!addingParticipants && (
                <>
                    <hr />
                    <div className={styles.target_group_info_box__scan_name}>
                        <h2>Scan used by this group</h2>
                    </div>
                    {currentCompany && (
                        <TargetGroupScan
                            currentCompany={currentCompany}
                            assessment={assessments[assessmentId].data}
                            scanGroupsCount={scanGroupsCount}
                        />
                    )}
                    <hr />
                </>
            )}
            {!addingParticipants &&
                !!targetGroup &&
                !!targetGroup.baseline &&
                !!workPreferenceBaseline &&
                !!workPreferenceBaseline.length && (
                    <div className={styles.delete_box}>
                        <div
                            className={styles.target_group_info_box__scan_name}>
                            <h2>Baseline used in this group</h2>
                        </div>
                        <WorkPreferenceBaseLineBarChart data={data} />
                        <hr />
                    </div>
                )}
            {!addingParticipants &&
                !!targetGroup &&
                workPreferenceBaseline.length === 0 && (
                    <div className={styles.delete_box}>
                        <div
                            className={styles.target_group_info_box__scan_name}>
                            <h2>Baseline used in this group</h2>
                        </div>
                        <p className={styles.delete_box__description}>
                            No baseline is available for this group.
                        </p>
                        <hr />
                    </div>
                )}
            {!addingParticipants && (
                <div className={styles.delete_box}>
                    <h3>Delete group</h3>
                    <p className={styles.delete_box__description}>
                        Deleting this group is permanent.
                    </p>
                    <AppButton
                        isDisabled={participants.length !== 0}
                        onClick={() => {
                            showDeleteTargetGroupModal(true);
                        }}>
                        Delete group
                    </AppButton>
                </div>
            )}
            <TargetGroupModals
                sendReminderEmails={sendReminderEmails}
                sendResetPasswordEmails={sendResetPasswordEmails}
                sendReportEmails={sendReportEmails}
                removeCandidates={removeCandidates}
                fullDeleteCandidates={fullDeleteCandidates}
                resetCandidateScan={resetCandidateScan}
                shouldShowResetCandidateScanSuccessModal={
                    shouldShowResetCandidateScanSuccessModal
                }
                deleteTargetGroup={deleteEmptyTargetGroup}
                isReportReadyToBeSent={isReportReadyToBeSent}
                participantsSelected={participantsSelected}
                participantsThatHaveReport={selectedParticipantsThatHaveReport()}
                participantsThatNotFinishedScan={selectedParticipantsThatNotFinishedScan()}
                shouldShowInviteModal={shouldShowInviteModal}
                shouldShowResetPasswordModal={shouldShowResetPasswordModal}
                shouldShowReminderModal={shouldShowReminderModal}
                shouldShowReportModal={shouldShowReportModal}
                shouldShowRemoveModal={shouldShowRemoveModal}
                shouldShowFullDeleteCandidateModal={
                    shouldShowFullDeleteCandidateModal
                }
                shouldShowResetCandidateScanModal={
                    shouldShowResetCandidateScanModal
                }
                shouldShowDeleteTargetGroupModal={
                    shouldShowDeleteTargetGroupModal
                }
                onDiscardModal={discardModals}
            />
        </div>
    );
};

export default TargetGroupView;
