import { put, call } from 'redux-saga/effects';
import { actionsTypes } from './targetGroups.actions';
import { actions as UsersActions } from '../users/users.actions';

import NeurolyticsAPI from 'services/NeurolyticsAPI';
import {
    TargetGroup,
    TargetGroupParticipant,
    WorkPreferenceBaselineData,
    FeedbackList,
} from 'typings/target_group';

export function* getCompanyGroups(action: { type: string; payload: any }) {
    try {
        const { companyId, type } = action.payload;

        const groups: TargetGroup[] = yield call(
            [NeurolyticsAPI, 'getCompanyGroupsRequest'],
            companyId,
            type,
        );
        yield put({
            type: actionsTypes.GET_COMPANY_GROUPS_SUCCESS,
            payload: { groups },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_COMPANY_GROUPS_FAILURE,
            payload: { error },
        });
    }
}

export function* getAssessmentTargetGroups(action: {
    type: string;
    payload: any;
}) {
    try {
        const assessmentId = action.payload.assessmentId;

        const targetGroups: TargetGroup[] = yield call(
            NeurolyticsAPI.getAssessmentTargetGroups,
            assessmentId,
        );
        yield put({
            type: actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_SUCCESS,
            payload: { assessmentId, targetGroups },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_ASSESSMENT_TARGET_GROUPS_FAILURE,
            payload: { error },
        });
    }
}

export function* getTargetGroupById(action: { type: string; payload: any }) {
    try {
        const { assessmentId, targetGroupId } = action.payload;

        const response: TargetGroup = yield call(
            NeurolyticsAPI.getTargetGroupById,
            assessmentId,
            targetGroupId,
        );
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_BY_ID_SUCCESS,
            payload: { assessmentId, targetGroupId, targetGroup: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_BY_ID_FAILURE,
            payload: { error },
        });
    }
}

export function* getWorkPreferenceBaselineEffect(action: {
    type: string;
    payload: any;
}) {
    try {
        const { assessmentId, targetGroupId } = action.payload;

        const baseline: WorkPreferenceBaselineData = yield call(
            [NeurolyticsAPI, 'getWorkPreferenceBaseline'],
            assessmentId,
            targetGroupId,
        );
        yield put({
            type: actionsTypes.GET_WORK_PREFERENCE_BASELINE_SUCCESS,
            payload: { targetGroupId, workPreferenceBaseline: baseline },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_WORK_PREFERENCE_BASELINE_FAILURE,
            payload: { error },
        });
    }
}

export function* deleteEmptyTargetGroup(action: {
    type: string;
    payload: any;
}) {
    try {
        const { assessmentId, targetGroupId, navigate, currentCompanySlug } =
            action.payload;

        yield call(
            NeurolyticsAPI.deleteEmptyTargetGroup,
            assessmentId,
            targetGroupId,
        );
        yield put({
            type: actionsTypes.DELETE_TARGET_GROUP_SUCCESS,
            payload: { assessmentId, targetGroupId },
        });
        navigate(`/${currentCompanySlug}`);
    } catch (error) {
        yield put({
            type: actionsTypes.DELETE_TARGET_GROUP_FAILURE,
            payload: { error },
        });
    }
}

export function* addParticipant(action: { type: string; payload: any }) {
    const { assessmentId, targetGroupId, participants } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.addParticipantsToTargetGroup,
            assessmentId,
            targetGroupId,
            participants,
        );

        yield put({
            type: actionsTypes.ADD_PARTICIPANTS_SUCCESS,
            payload: {
                assessmentId,
                targetGroupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.ADD_PARTICIPANTS_FAILURE,
            payload: { error, targetGroupId },
        });
    }
}

export function* removeParticipant(action: { type: string; payload: any }) {
    const {
        assessmentId,
        targetGroupId,
        participants,
    }: {
        assessmentId: string;
        targetGroupId: string;
        participants: TargetGroupParticipant[];
    } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.removeParticipantsFromTargetGroup,
            assessmentId,
            targetGroupId,
            participants,
        );

        for (const participant of participants) {
            yield put(
                UsersActions.removeParticipant(
                    targetGroupId,
                    participant.user_id,
                ),
            );
        }
        yield put({
            type: actionsTypes.REMOVE_PARTICIPANTS_SUCCESS,
            payload: {
                assessmentId,
                targetGroupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.REMOVE_PARTICIPANTS_FAILURE,
            payload: { error, targetGroupId },
        });
    }
}

export function* fullDeleteParticipant(action: { type: string; payload: any }) {
    const {
        assessmentId,
        targetGroupId,
        participants,
    }: {
        assessmentId: string;
        targetGroupId: string;
        participants: TargetGroupParticipant[];
    } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.fullDeleteParticipantsFromTargetGroup,
            assessmentId,
            targetGroupId,
            participants,
        );

        for (const participant of participants) {
            yield put(
                UsersActions.removeParticipant(
                    targetGroupId,
                    participant.user_id,
                ),
            );
        }
        yield put({
            type: actionsTypes.FULL_DELETE_PARTICIPANTS_SUCCESS,
            payload: {
                assessmentId,
                targetGroupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.FULL_DELETE_PARTICIPANTS_FAILURE,
            payload: { error, targetGroupId },
        });
    }
}

export function* updateTargetGroupConfig(action: {
    type: string;
    payload: any;
}) {
    const { assessmentId, targetGroupId, data } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.updateTargetGroupConfig,
            assessmentId,
            targetGroupId,
            data,
        );
        yield put({
            type: actionsTypes.UPDATE_TARGET_GROUP_CONFIG_SUCCESS,
            payload: {
                assessmentId,
                targetGroupId,
                targetGroup: response.targetGroup,
            },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.UPDATE_TARGET_GROUP_CONFIG_FAILURE,
            payload: { error, targetGroupId },
        });
    }
}

export function* createTargetGroup(action: { type: string; payload: any }) {
    const { assessmentId, data } = action.payload;

    try {
        const response: { targetGroup: TargetGroup } = yield call(
            NeurolyticsAPI.createTargetGroup,
            assessmentId,
            data,
        );

        yield put({
            type: actionsTypes.CREATE_TARGET_GROUP_SUCCESS,
            payload: { targetGroup: response.targetGroup },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.CREATE_TARGET_GROUP_FAILURE,
            payload: { error, assessmentId },
        });
    }
}

export function* getTargetGroupFeedbackEffect(action: {
    type: string;
    payload: any;
}) {
    const { companyId, targetGroupId } = action.payload;
    try {
        const response: FeedbackList = yield call(
            [NeurolyticsAPI, 'getTargetGroupFeedback'],
            companyId,
            targetGroupId,
        );

        yield put({
            type: actionsTypes.GET_TARGET_GROUP_FEEDBACK_SUCCESS,
            payload: { targetGroupId, feedback: response },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.GET_TARGET_GROUP_FEEDBACK_FAILURE,
            payload: { targetGroupId, error },
        });
    }
}

export function* resetCandidateScanEffect(action: {
    type: string;
    payload: any;
}) {
    const { companyId, targetGroupId, userId } = action.payload;
    try {
        yield call(
            [NeurolyticsAPI, 'resetCandidateScan'],
            companyId,
            targetGroupId,
            userId,
        );

        yield put(UsersActions.getUserActivity(targetGroupId, userId, false));
        yield put(UsersActions.getUserEmailInfo(targetGroupId, userId));

        yield put({
            type: actionsTypes.RESET_CANDIDATE_SCAN_SUCCESS,
            payload: { targetGroupId },
        });
    } catch (error) {
        yield put({
            type: actionsTypes.RESET_CANDIDATE_SCAN_FAILURE,
            payload: { targetGroupId },
        });
    }
}
